


























































import { Vue, Component } from 'vue-property-decorator';
import { placeModule } from '@/store';
import momnet from 'moment';
import ThumbnailDetailModal from '@/pages/detail/ThumbnailDetailModal.vue';
import { EventBus } from '@/config/config';

@Component({
  components: {
    'thumbnail-detail-modal': () => import('@/pages/modal/ThumbnailDetailModal.vue'),
  }
})
export default class PlaceMenu extends Vue {
  public tab: any = '0';
  public isUpdate: boolean = false;
  public fetchMenu: any = [];

  public beforeRouteEnter(to, from, next) {
    placeModule.fetchPlaceInfoData(to.params.placeId);
    placeModule.fetchPlaceMenu(to.params.placeId);
    next();
  }

  public thumbnailDetail(index, menuIndex) {
    EventBus.$emit('thumbnailIndex', index);
    this.fetchMenu = this.placeMenu[menuIndex];
    this.$modal.show('thumbnailDetail');
  }

  public updateToggle() {
    this.isUpdate = !this.isUpdate;
  }
  get place() {
    return placeModule.placeInfoData;
  }
  get placeMenu() {
    return placeModule.placeMenu;
  }
  get menuUnit() {
    return placeModule.placeMenuUnit;
  }
  get selectLang() {
    return this.$attrs['select-lang'];
  }
}
